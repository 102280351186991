import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { LanguageService } from './language.service';
import { Platform } from '@ionic/angular';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'multipart/x-www-form-urlencoded',
  })
};

@Injectable({
  providedIn: 'root'
})
export class ServerApiService {
  apiURL : string = 'https://onlinereg.globalprestasi.sch.id/admin/api/client_api_portal_2024/';
  // apiURL : string = 'https://psb.dev.globalprestasi.sch.id/admin/api/client_api_portal_2024/';
  // apiURL : string = 'http://10.0.0.9/siap/gps_psb/admin/api/client_api_portal/';

  domainUrl: string = 'onlinereg.globalprestasi'
  // domainUrl: string = 'psb.dev.globalprestasi'

  language = null;


  DATA_USER_KEY = 'dataUser';
  DATA_UNIT_KEY = 'dataUnits';
  DATA_CLASS_TYPE_KEY = 'dataClassType';
  DATA_PREVIOUS_SCHOOL_TYPE_KEY = 'datapPreviousSchoolType';

  HAS_LOGGED_IN = '0';

  SELECTED_LANG = 'id';
  DATA_LANGUAGE_KEY = 'dataLanguage';

  fcm_tokey_key:string='';
  branchConfig:any = null;
  appVersion:any = null;

  constructor(
    public http: HttpClient,
    private storage: StorageService,
    private lang: LanguageService,
    private plt: Platform    
  ) { 
    // this.getLanguage();
    this.getDataBranch().then((res:any) => {
      console.log('data branch', res);
      this.branchConfig = res.data;
    });
  }


  register(value){
    return this.httpPostServer('register',  value);    
  }
 
  validateAccount(params) {
    return this.httpPostServer('val',  params);    
  }

  updatePassword(params){
    return this.httpPostServer('update_password',  params);    
  }

  async getDataUser() {
    return await this.storage.get(this.DATA_USER_KEY)
  }

  getLanguage(params){
    return this.httpPostServer('get_language', params);
  }

  getDataApplicant(params): Promise<any> {
    return this.httpPostServer('getApplicant', params);    
  }

  validateResetParams(params) {
    return this.httpPostServer('validate_reset_params',  params);    
  }

  getDataBranch() {
    return this.httpPostServer('get_branch',  null);   
  }

  getDataCurriculumYears() {
    return this.httpPostServer('get_curriculum_years',  null);    
  }

  getDataCurriculumYearRereg(){
    return this.httpPostServer('get_curriculum_years_rereg',  null);    
  }  

  async getDataPreviousSchoolType() {
      this.storage.remove(this.DATA_PREVIOUS_SCHOOL_TYPE_KEY);
      return await this.storage.get(this.DATA_PREVIOUS_SCHOOL_TYPE_KEY).then((data) => {
        if (data) {
          return data;
        }
        else {
          return this.httpPostServer('get_previous_school_type',  null);    
        }
      });
  
  } 

  sendMailResetPassword(params) {
    return this.httpPostServer('send_mail_reset_password',  params);    
  }

  updateProfile(params) {
    return this.httpPostServer('profile', params);    
  }

  requestDeleteAccount(params) {
    return this.httpPostServer('request_delete_account', params);    
  }

  deleteAccount(params) {
    return this.httpPostServer('delete_account', params);    
  }

  updateDataUserLocal(objUpdate): Promise<any> {
    return this.storage.get(this.DATA_USER_KEY).then((value) => {
      if (value) {
        let dataUser = JSON.parse(value);

        for (var key in objUpdate) {
          if (objUpdate.hasOwnProperty(key)) {
            if (objUpdate[key] !== null) {
              dataUser[key] = objUpdate[key];
              if (key == 'lang') this.lang.changeLanguage(objUpdate[key]);
            }

          }
        }  

        this.storage.set(this.DATA_USER_KEY, JSON.stringify(dataUser));
      }
    });
  }

  doSubmission(value){
    return this.httpPostServer('submission',  value);    
  }

  getDataSubmissionByUser(UserID): Promise<any> {
    return this.httpPostServer('active_submission',  {user_id : UserID});
  };

  getNotifications(UserID): Promise<any> {
    return this.httpPostServer('get_notifications',  {user_id : UserID});    
  };

  getDataUnits() {
    return this.httpPostServer('get_units',  null);    
  }

  getDataUnitsRereg() {
    return this.httpPostServer('get_units_rereg',  null);    
  }  

  getGradeAgeLimit(params) {
    return this.httpPostServer('get_age_limit',  params);    
  }

  getPernyataanTemplate(params, branch) {
    return this.httpPostServerBranch('generate_pernyataan', params, branch);    
  }

  getReregistrationFee(params) {
    return this.httpPostServer('get_reregistration_fee', params);    
  }    
  
  checkReregistration(params) {
    return this.httpPostServer('check_reregistration', params);    
  }    
  
  reregistration(params) {
    return this.httpPostServer('reregistration', params);    
  }    

  getDataReregistrationById(params) {
    return this.httpPostServer('get_reregistration_by_id', params);    
  }    

  getDataReregistrationByIdMidtrans(params) {
    return this.httpPostServer('get_reregistration_by_id_midtrans', params);    
  }    

  getDataReregistrationByUser(params) {
    return this.httpPostServer('get_reregistration_by_user', params);    
  }      

  payReregistrationVa(params) {
    return this.httpPostServer('get_reregistration_by_id', params);    
  }      


  getBookUrl(idReregistration) {
    return this.httpPostServer('book_url/'+idReregistration, null);    
  }


  paySubmissionVa(params): Promise<any> {
    return this.httpPostServer('payment_va', params);    
  }

  paySubmissionVaBca(params): Promise<any> {
    return this.httpPostServer('payment_va_bca', params);    
  }

  paySubmissionVaBcaSprint(params): Promise<any> {
    return this.httpPostServer('payment_va_bca_sprint', params);    
  }

  paySubmissionCC(params): Promise<any> {
    return this.httpPostServer('payment_cc', params);    
  }  

  getSubmissionFare(idSubmission): Promise<any> {
    return this.httpPostServer('get_submission_fare', {'submission_id' : idSubmission});    
  }

  getDataPreparePaymentVa(params): Promise<any> {
    return this.httpPostServer('prepare_payment_va', params);    
  }  

  getDataPreparePaymentVaBca(params): Promise<any> {
    return this.httpPostServer('prepare_payment_va_bca', params);    
  }  

  getDataPreparePaymentMidtrans(params) {
    return this.httpPostServer('prepare_payment_midtrans', params);
  }

  getMidtransUrl(params) {
    return this.httpPostServer('getMidtransUrl', params);
  }

  getMidtransUrlRereg(params) {
    return this.httpPostServer('getMidtransUrlRereg', params);
  }

  getDataPreparePaymentVaBcaSprint(params): Promise<any> {
    return this.httpPostServer('prepare_payment_va_bca_sprint', params);    
  }  

  getPaymentMethodInfo(params): Promise<any> {   
      return this.httpPostServer('get_payment_method', params);    
  }  

  getDataCountry() {
    return this.httpPostServer('get_country', null);    
  }

  async setLocalDataApplicant(id_applicant, dataParams) {
    return await this.storage.set('applicant_'+id_applicant, dataParams)
  } 

  async getLocalDataApplicant(id_applicant) {
    return await this.storage.get('applicant_'+id_applicant)
  }

  async removeLocalDataApplicant(id_applicant) {
    return await this.storage.remove('applicant_'+id_applicant)
  }  

  async setLocalDataSubmission(idUser, dataParams) {
    return await this.storage.set('submission_data_'+idUser, dataParams)
  } 

  async getLocalDataSubmission(idUser) {
    return await this.storage.get('submission_data_'+idUser)
  }  

  async removeLocalDataSubmission(idUser) {
    return await this.storage.remove('submission_data_'+idUser)
  }  

  async setLocalDataRereg(idUser, dataParams) {
    return await this.storage.set('rereg_data_'+idUser, dataParams)
  } 

  async getLocalDataRereg(idUser) {
    return await this.storage.get('rereg_data_'+idUser)
  }  

  async removeLocalDataRereg(idUser) {
    return await this.storage.remove('rereg_data_'+idUser)
  }  

  getsubmissionDocument(idSubmission) {
    return this.httpPostServer('submission_receipt/'+idSubmission, null);    
  }     

  getDataSubmissionEntranceType() {
    return this.httpPostServer('get_submission_entrance_type', null);    
  }

  submitApplicant(value){
    return this.httpPostServer('applicant',  value);    
  }

  getDataReligion(): Promise<any> {
    return this.httpPostServer('getReligion', null);    
  }
  
  getDataProvince(): Promise<any> {
    return this.httpPostServer('get_province', null);    
  }
    
  getDataInfoSource(): Promise<any> {
    return this.httpPostServer('get_info_source', null);    
  }

  login(value){
    if (this.plt.is('cordova'))
      value.device_token_fcm = this.fcm_tokey_key;

    return this.httpPostServer('login', value);    
  }

  async logout(){
    await this.storage.remove(this.DATA_USER_KEY);
    await this.storage.set(this.HAS_LOGGED_IN, '0');
    return '';
  }

  checkPayment(params) {
    // this.storage.remove(this.DATA_UNIT_KEY);
    return this.httpPostServer('check_payment',  params);    
  }

  async httpPostServer(url, params){
    return await new Promise((resolve, reject) => {
      this.http.post(this.apiURL+url, params, httpOptions).pipe(
        timeout(30000)
      )
      .subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });     
  }

  async httpPostServerBranch(url, params, branch){
    //          let urlUpload = this.serverApi.branchConfig[this.branch]['api_url'] + '/';
    let apiUrl = this.branchConfig[branch]['api_url'] + '/' + url;
    return await new Promise((resolve, reject) => {
      this.http.post(apiUrl, params, httpOptions).pipe(
        timeout(30000)
      )
      .subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });     
  }
}
