import { Injectable } from '@angular/core';
import { ServerApiService } from './server-api.service';
import { Router } from '@angular/router';
import { AlertController, LoadingController, MenuController } from '@ionic/angular';
import { UserData } from '../interfaces/UserData';
import { LanguageService } from '../services/language.service';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  dataUser : UserData;

  constructor(
    private serverApi : ServerApiService,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private lang: LanguageService,
    public menuCtrl: MenuController,
    private router : Router,
    private iab : InAppBrowser,
  ) { }

  async checkIsLogin(bolRedirect) : Promise<void>{
    return new Promise (async (resolve, reject) => {
      await this.serverApi.getDataUser().then(async (data) => {
        if (typeof data != 'undefined') {
          if (data != null) {
            this.dataUser = JSON.parse(data);
            this.menuCtrl.enable(true, "sideMenu");
            if (!bolRedirect) this.router.navigateByUrl('/dashboard');
            else {
              if (typeof this.dataUser.lang !== 'undefined')
                await this.lang.changeLanguage(this.dataUser.lang);  
            }
            resolve()
          }
          else {
            reject()
          }
        }
        else {
          this.menuCtrl.enable(false, "sideMenu")
        }
      }, async (err) => {
        this.menuCtrl.enable(false, "sideMenu")
        if(bolRedirect) this.router.navigateByUrl('/');
        else await this.lang.getLanguageFromServer();
        reject();
      });
    });

  }

  async showAlertNotif(errorMsg, headerText) {
    const alert = await this.alertCtrl.create({
      header: headerText,
      message: errorMsg,
      buttons: ['OK']
    });

    return await alert.present();
  }   

  async logOutUser() {
    await this.serverApi.logout().then((data) => {
      this.menuCtrl.enable(false, "sideMenu");
      this.router.navigateByUrl('/');
    });
  }

  async openPaymentPage(redirectUrl) {
    var browserOption: InAppBrowserOptions = {
      clearcache : 'yes',
      cleardata : 'yes',
      clearsessioncache : 'yes',
      location : 'no',
      hideurlbar : 'yes',
      toolbar : 'no',
      usewkwebview : 'yes',
      hidden: 'yes',
      transitionstyle : 'fliphorizontal'
    };
    
    const loadingElement = await this.loadingCtrl.create({
      message: 'Opening page...',
      spinner: 'crescent',
    });
    
    const browser = this.iab.create(redirectUrl, "_self", browserOption); 

    browser.on('loadstart').subscribe(async event => {
      console.log(event);
      // await loadingElement.present();
    }, err => {
      this.showAlertNotif(err, 'Error Loading Page');
    }); 

    browser.on('loadstop').subscribe((event) => {
      console.log(event);

      if (event.url.indexOf('admin/api/Midtrans_callback/finish_redirect') != -1) {
        this.router.navigateByUrl('/dashboard');
        browser.close();
      }
      else {
        setTimeout( () => {
          // loadingElement.dismiss();
          browser.show();
        }, 1000);
      }
    },
    err => {
      loadingElement.dismiss();
      this.showAlertNotif('Please check your internet connection', 'Error Loading Page');
    });

    browser.on('message').subscribe(browserParams => {
      console.log(browserParams);

      switch(browserParams.data.action) {
        case 'redirect-dashboard' : 
          this.router.navigateByUrl('/dashboard');
          browser.close();
          break;

        default :
      }
    },
    (err) => {

    },() => {
      // console.log('complete');
    });    
  }

}
